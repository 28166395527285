import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { format } from "date-fns";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: false,
  },
};
export function SevenDaysChart({ data }) {
  const chartData = {
    labels: data.slice(-7).map((it) => it.day),
    datasets: [
      {
        data: data.slice(-7).map((it) => it.value),
        borderColor: "rgba(203, 213, 225, 0.5)",
        backgroundColor: "rgba(203, 213, 225, 0.5)",
      },
    ],
  };
  return <Line options={options} data={chartData} />;
}
export function ThirtyDaysChart({ data }) {
  const chartData = {
    labels: data.map((it) => it.day),
    datasets: [
      {
        data: data.map((it) => it.value),
        borderColor: "rgba(203, 213, 225, 0.5)",
        backgroundColor: "rgba(203, 213, 225, 0.5)",
      },
    ],
  };
  return <Line options={options} data={chartData} />;
}
export function TotalChart({ data }) {
  const optionsFull = {
    responsive: true,
    elements: {
      point: {
        radius: 0,
        hoverRadius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: false,
    },
  };
  const chartData = {
    labels: Object.keys(data).map((it) => {
        const nd = new Date(data[it].date);
        return format(nd, "dd-MM-yyyy");
    }),
    datasets: [
      {
        data: Object.keys(data).map((it) => data[it].lateTime),
        borderColor: "rgba(203, 213, 225, 0.5)",
        backgroundColor: "rgba(203, 213, 225, 0.5)",
      },
    ],
  };
  return <Line options={optionsFull} data={chartData} />;
}
