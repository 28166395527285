import { child, get, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { real_db, stor_db } from "../../../config/firebaseConfig";
import { getDownloadURL, ref as ref_storage } from "firebase/storage";
import { Link } from "react-router-dom";
export default function UsersSelector() {
  const [users, setUsers] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const snap = await get(child(ref(real_db), "usersInterpol/"));
      if (snap.val() !== null) {
        let fu = snap.val();
        const promises = Object.keys(fu).map(async (it) => {
          const snap2 = await getDownloadURL(
            ref_storage(stor_db, "usersInterpol/" + fu[it].image)
          );
          fu[it].image = snap2;
          fu[it].path = it;
        });
        await Promise.all(promises);
        setUsers(Object.values(fu));
      }
    };
    fetchData();
  }, []);
  console.log(users);
  return (
    <div className="w-full flex justify-center items-start">
      <div className="flex flex-col items-center justify-center w-4/5">
        <p className="text-slate-300 text-center text-4xl">
          Wybierz osobę do edytowania:{" "}
        </p>
        <div className=" text-slate-300 grid grid-cols-4 gap-8 mt-8 w-full">
          {users &&
            Object.keys(users).map((it, index) => {
              return (
                <Link to={`/interpol/edit/${users[it].path}`} key={index}>
                  <div className="bg-slate-300 text-black rounded-xl p-4 min-h-40 hover:bg-slate-400">
                    <p className="text-center text-2xl">{users[it].name}</p>
                    
                    <img src={users[it].image} alt={users[it].name} />
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    </div>
  );
}
