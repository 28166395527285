// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCPfZmjWuVS-UVTZSPZGHhJoispIfLO0iM",
    authDomain: "plebiscytbzdura.firebaseapp.com",
    databaseURL:
        "https://plebiscytbzdura-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "plebiscytbzdura",
    storageBucket: "plebiscytbzdura.appspot.com",
    messagingSenderId: "1041568577596",
    appId: "1:1041568577596:web:9eb62d36e0e9fd0ba96b39",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const real_db = getDatabase(app);
export const stor_db = getStorage(app);
export const fire_db = getFirestore(app);
