import React, { useState, useEffect } from "react";
import { startOfMonth, endOfMonth, addDays, format } from "date-fns";
import { SevenDaysChart, ThirtyDaysChart, TotalChart } from "./Charts";
export default function CalcStats({ data }) {
  const [currentMonthAverage, setCurrentMonthAverage] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [last7DaysAverage, setLast7DaysAverage] = useState(0);
  const [last30DaysAverage, setLast30DaysAverage] = useState(0);
  const [last30DaysSum, setLast30DaysSum] = useState(0);
  const [last7DaysSum, setLast7DaysSum] = useState(0);
  const [last30Days, setLast30Days] = useState(null);

  useEffect(() => {
    calculateStatistics();
    // eslint-disable-next-line
  }, [data]);

  //=======================================================
  const calculateStatistics = () => {
    const today = new Date();
    const firstDayOfMonth = startOfMonth(today);
    const lastDayOfMonth = endOfMonth(today);
    const sevenDayBack = format(addDays(today, -6), "dd-MM-yy");
    const last30Days = [];
    let currentMonthSum = 0;
    let currentMonthCounter = 0;
    let last7DaysCounter = 0;
    let last30DaysCounter = 0;

    for (let i = 29; i >= 0; i--) {
      last30Days.push({
        day: format(addDays(today, -i), "dd-MM-yy"),
        value: 0,
      });
    }
    Object.values(data).forEach((item) => {
      const itemDate = new Date(item.date);
      const itemDateFormat = format(itemDate, "dd-MM-yy");
      last30Days.forEach((it) => {
        if (it.day === itemDateFormat) {
          it.value += item.lateTime;
          last30DaysCounter++;
          if (sevenDayBack <= itemDateFormat) {
            last7DaysCounter++;
          }
        }
      });

      //Suma wartości dla bieżącego miesiąca
      if (itemDate >= firstDayOfMonth && itemDate <= lastDayOfMonth) {
        currentMonthSum += item.lateTime;
        currentMonthCounter++;
      }
    });

    // Oblicz średnią dla bieżącego miesiąca
    const currentMonthAverageTemp = currentMonthSum / currentMonthCounter++;

    //RDY FOR PROD
    const last7DaysSumTemp = last30Days
      .slice(-7)
      .reduce((acc, item) => acc + item.value, 0);

    const last30DaysSumTemp = last30Days.reduce(
      (acc, item) => acc + item.value,
      0
    );

    // Oblicz średnią dla ostatnich 7 dni
    const last7DaysAverageTemp =
      last7DaysCounter === 0 ? 0 : last7DaysSumTemp / last7DaysCounter;
    const last30DaysAverageTemp =
      last30DaysCounter === 0 ? 0 : last30DaysSumTemp / last30DaysCounter;

    setLast30Days(last30Days);
    setLast7DaysAverage(last7DaysAverageTemp.toFixed(2));
    setLast30DaysAverage(last30DaysAverageTemp.toFixed(2));
    setCurrentMonthAverage(currentMonthAverageTemp.toFixed(2));
    setLast30DaysSum(last30DaysSumTemp);
    setLast7DaysSum(last7DaysSumTemp);
    //RDY FOR PROD
    const totalSumTemp = Object.values(data).reduce(
      (acc, item) => acc + item.lateTime,
      0
    );
    //RDY FOR PROD
    setTotalSum(totalSumTemp);
    //==========================================
  };
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <div className="flex flex-col rounded-xl border-slate-300 border-2 p-2 h-32">
        <div className="flex justify-between w-full">
          <p>ŁĄCZNIE:</p>
          <p className="text-4xl">{totalSum}</p>
        </div>
        <div className="">{/* MIEJSCE NA WYKRES */}</div>
      </div>
      <div className="flex rounded-xl border-slate-300 border-2 p-2 h-32">
        <div className="flex justify-between w-full">
          <p>ŚREDNIA MIESIĘCZNA:</p>
          <p className="text-4xl">{currentMonthAverage}</p>
        </div>
        <div className="">{/* MIEJSCE NA WYKRES */}</div>
      </div>
      <div className="flex flex-col rounded-xl border-slate-300 border-2 p-2 h-32">
        <div className="flex justify-between w-full">
          <p>SUMA 30 DNI:</p>
          <p className="text-4xl">{last30DaysSum}</p>
        </div>
        <div className="h-1/2 w-1/2">
          {last30Days && <ThirtyDaysChart data={last30Days} />}
        </div>
      </div>
      <div className="flex rounded-xl border-slate-300 border-2 p-2 h-32">
        <div className="flex justify-between w-full">
          <p>ŚREDNIA 30 DNI:</p>
          <p className="text-4xl">{last30DaysAverage}</p>
        </div>
        <div className="">{/* MIEJSCE NA WYKRES */}</div>
      </div>
      <div className="flex flex-col rounded-xl border-slate-300 border-2 p-2 h-32">
        <div className="flex justify-between w-full">
          <p>SUMA 7 DNI:</p>
          <p className="text-4xl">{last7DaysSum}</p>
        </div>
        <div className="w-auto h-1/2">
          {last30Days && <SevenDaysChart data={last30Days} />}
        </div>
      </div>
      <div className="flex rounded-xl border-slate-300 border-2 p-2 h-32">
        <div className="flex justify-between w-full">
          <p>ŚREDNIA 7 DNI:</p>
          <p className="text-4xl">{last7DaysAverage}</p>
        </div>
        <div className="">{/* MIEJSCE NA WYKRES */}</div>
      </div>
      <div className="col-span-1 md:col-span-2 lg:col-span-3 rounded-xl border-slate-300 border-2 p-2">
        <div className="w-auto h-auto">
          {data && <TotalChart data={data} />}
        </div>
      </div>
    </div>
  );
}
