import React, { useState } from "react";
import ReactTurnable from "react-turntable";
import closeIcon from "../../assets/closeIcon.svg";
import "react-turntable/assets/index.css";
export default function PickerWheel() {
  const [people, setPeople] = useState([]);
  const [person, setPerson] = useState("");
  const [winner, setWiner] = useState(null);

  //   const [errorIndicator, setErrorIndicator] = useState(null);

  const handlePeopleChange = (e) => {
    setPerson(e.target.value);
  };

  const addPerson = () => {
    // setErrorIndicator(null);
    setWiner(null)
    if (person !== "") {
      setPeople((prev) => [...prev, person]);
      setPerson("");
    } else {
      //   setErrorIndicator("PODAJ OSOBĘ...");
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addPerson();
    }
  };

  const handleRemovePerson = (personToRemove) => {
    const updatedPeople = people.filter((person) => person !== personToRemove);
    setPeople(updatedPeople);
  };

  const Wheel = () => {
    let prizes = [];
    if (people.length <= 0) {
      prizes = ["YES", "NO"];
    } else {
      prizes = people;
    }

    const options = {
      prizes,
      width: 500,
      height: 500,
      primaryColor: "rgb(100 116 139)",
      secondaryColor: "rgb(51 65 85)",
      fontStyle: {
        color: "#fff",
        size: "24px",
        fontVertical: true,
        fontWeight: "bold",
        fontFamily: "Microsoft YaHei",
      },
      speed: 1000,
      duration: 5000,
      clickText: "Start",
      onStart() {
        setWiner(null);
        return true;
      },
      onComplete(prize) {
        setWiner(prize);
      },
    };
    return (
      <div className="flex items-center justify-center">
        <ReactTurnable {...options} />
      </div>
    );
  };

  return (
    <div className="w-full flex justify-center items-start">
      <div className="w-5/6 flex flex-col bg-slate-300 mt-8 rounded p-4">
        <p className="text-xl">Koło sądu najwyższego:</p>
        <div className="flex flex-col items-start lg:flex-row lg:items-center">
          <div className="flex flex-col">
            <p>Dodaj osoby:</p>
            <div className="my-4">
              <input
                type="text"
                name="name"
                id="name"
                onChange={handlePeopleChange}
                className="p-2 rounded w-40 md:max-w-52 md:w-auto"
                value={person}
                onKeyPress={handleKeyPress}
              />
              <button
                type="sumbit"
                className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center ml-2"
                onClick={addPerson}
              >
                DODAJ
              </button>
            </div>
            <div className="flex flex-col items-start gap-2">
              <p>Dodane osoby:</p>
              {people &&
                people.map((pep, index) => {
                  return (
                    <div className="flex justify-between w-full" key={index}>
                      <div className="text-lg flex items-center">{pep}</div>
                      <button
                        className="bg-red-500 p-1 rounded-md hover:bg-red-700 flex justify-center items-center"
                        onClick={() => handleRemovePerson(pep)}
                      >
                        <img src={closeIcon} alt="closeIcon" />
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            {people.length >= 2 && <Wheel />}
            Wygrał/ła:{winner &&  <p className="text-4x">{winner}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
