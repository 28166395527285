import { child, get, ref } from "firebase/database";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { real_db } from "../../config/firebaseConfig";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Expense() {
  //Parametry z paska
  const { expId } = useParams();

  //Dane pobrane z bazy
  const [fetchedData, setFetchedData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = await get(child(ref(real_db), "splitApp/" + expId));
      if (data.val() !== null) {
        setFetchedData(data.val());
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedPeople, setSelectedPeople] = useState([]);
  const [amountPaid, setAmountPaid] = useState(100);
  const [whoPaid, setWhoPaid] = useState("");
  const [errorIndicator, setErrorIndicator] = useState(null);
  //done
  const calculateValue = (newAmountPaid, newSelectedPeople) => {
    return newAmountPaid
      ? (parseFloat(newAmountPaid) / newSelectedPeople.length).toFixed(2)
      : "";
  };

  //done
  const calculatePercentage = (personValue, totalAmountPaid) => {
    return ((personValue / totalAmountPaid) * 100).toFixed(2);
  };

  //done
  const updateFields = (newAmountPaid, newWhoPaid, newSelectedPeople) => {
    const updatedPeople = newSelectedPeople.map((person) => ({
      ...person,
      value: calculateValue(newAmountPaid, newSelectedPeople),
      percentage: calculatePercentage(
        calculateValue(newAmountPaid, newSelectedPeople),
        parseFloat(newAmountPaid)
      ),
      manualyChanged: false,
    }));
    setSelectedPeople(updatedPeople);
    setAmountPaid(newAmountPaid);
    setWhoPaid(newWhoPaid);
  };
  console.log("selectedPeople", selectedPeople);
  //done
  const handleCheckboxChange = (name) => {
    const newSelectedPeople = selectedPeople.some(
      (person) => person.name === name
    )
      ? selectedPeople.filter((person) => person.name !== name)
      : [
          ...selectedPeople,
          {
            name,
            value: calculateValue(amountPaid, [...selectedPeople, { name }]),
            manualyChanged: false,
          },
        ];

    updateFields(amountPaid, whoPaid, newSelectedPeople);
  };
  const inpRef = useRef([]);
  const handleInputChange = (name, value) => {
    setErrorIndicator(null);
    let amountLeft = 0;
    if (value >= amountPaid) {
      setErrorIndicator("NIEPOPRAWNA ILOŚĆ");
      inpRef.current[name].blur();
      updateFields(amountPaid, whoPaid, selectedPeople);
    } else {
      //zmiana stanu na true ze byl edytowany
      const tempPeople = selectedPeople.map((person) => {
        if (person.name === name) {
          return {
            ...person,
            value,
            manualyChanged: true,
            percentage: calculatePercentage(value, amountPaid),
          };
        } else {
          return person;
        }
      });
      //filter tylko ludzi ktorzy maja false
      const peopleWithFalse = tempPeople.filter((p) => {
        if (p.manualyChanged === false) {
          return p;
        } else {
          amountLeft += parseFloat(p.value);
          return null;
        }
      });
      if (amountLeft > amountPaid) {
        setErrorIndicator("NIEPOPRAWNA ILOŚĆ");
        inpRef.current[name].blur();
        updateFields(amountPaid, whoPaid, selectedPeople);
      } else {
        const newValue = calculateValue(
          amountPaid - amountLeft,
          peopleWithFalse
        );
        console.log("tu ustawiam dopiero");
        const updatedPeople = tempPeople.map((p) => {
          if (p.manualyChanged === false) {
            return { ...p, value: newValue };
          } else {
            return p;
          }
        });
        setSelectedPeople(updatedPeople);
      }
    }
    
    // console.log(totalSum);
    // setSelectedPeople(updatedPeople);
  };
  const handleAmountPaidChange = (e) => {
    const newAmountPaid = e.target.value;
    if (newAmountPaid >= 0) {
      const newSelectedPeople = selectedPeople.map((person) => ({
        ...person,
        value: calculateValue(newAmountPaid, selectedPeople),
      }));
      updateFields(newAmountPaid, whoPaid, newSelectedPeople);
    } else {
      setAmountPaid("");
      setErrorIndicator("JAK TO ZA DARMO...");
    }
  };
  const handleWhoPaid = (e) => {
    const newWhoPaid = e.target.value;

    const newSelectedPeople = selectedPeople.filter(
      (person) => person.name !== newWhoPaid
    );
    updateFields(amountPaid, newWhoPaid, newSelectedPeople);
    setWhoPaid(newWhoPaid);
  };

  const [nameOfExpense, setNameOfExpense] = useState("");
  const handleNameOfExpense = (e) => {
    setNameOfExpense(e.target.value);
  };

  const handleSubmit = () => {
    const data = {
      amountPaid: amountPaid,
      whoPaid: whoPaid,
      people: selectedPeople,
      nameOfExpense: nameOfExpense,
    };
    console.log(data);
  };
  const Chart1 = () => {
    const data = {
      // labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
      labels: selectedPeople.map(p=> p.name),
      datasets: [
        {
          // data: [12, 19, 3, 5, 2, 3],
          data: selectedPeople.map( p=>p.percentage),
        },
      ],
    };
    return (
      <div className=" w-64 h-64">
        <Pie data={data} />
      </div>
    );
  };

  // console.log("tablica zwrotna", selectedPeople);
  return (
    <div className="flex justify-center items center">
      <div className="w-2/3 bg-slate-300 p-4 rounded-lg mt-8">
        <div>
          {/* <button onClick={calculateLeftAmount}>Aa</button> */}
          <p>Na co wydano:</p>
          <input
            type="text"
            name="nameOfExpense"
            id="nameOfExpense"
            className="p-1 rounded bg-transparent border-dashed border-2 border-slate-600"
            onChange={handleNameOfExpense}
          />
          <p>Kwota:</p>
          <input
            type="number"
            id="amountPaid"
            value={amountPaid}
            onChange={handleAmountPaidChange}
            className="p-1 rounded bg-transparent border-dashed border-2 border-slate-600"
          />
        </div>
        <p>Kto płacił</p>
        <div className="">
          <fieldset id="whoPaid" className="grid grid-cols-4 gap-2 w-1/2">
            {fetchedData &&
              fetchedData.people.map((it, index) => {
                return (
                  <label key={index}>
                    <div className=" border-2 border-slate-600 flex justify-center items-center p-2 max-w-32 has-[:checked]:border-red-500">
                      <input
                        type="radio"
                        name="whoPaid"
                        id={it.name}
                        value={it.name}
                        onChange={handleWhoPaid}
                        className=" hidden"
                      />
                      {it.name}
                    </div>
                  </label>
                );
              })}
          </fieldset>
        </div>
        <p>Kto się składał:</p>
        <div id="whoParticipated" className="flex flex-col gap-3 w-2/3">
          {amountPaid >0 &&
            whoPaid !== "" &&
            fetchedData.people.map((person, index) => {
              return (
                <label
                  key={person.name}
                  className="flex flex-row justify-between w-2/3 has-[:checked]:bg-slate-400 p-2 hover:bg-slate-400 "
                >
                  <input
                    type="checkbox"
                    id={index}
                    onChange={() => handleCheckboxChange(person.name)}
                    checked={selectedPeople.some((p) => p.name === person.name)}
                    className="hidden"
                  />
                  <p>{person.name}</p>
                  <input
                    type="number"
                    value={
                      selectedPeople.find((p) => p.name === person.name)
                        ?.value || ""
                    }
                    onChange={(e) =>
                      handleInputChange(person.name, e.target.value)
                    }
                    className={
                      selectedPeople.some((p) => p.name === person.name)
                        ? "inline"
                        : "hidden"
                    }
                    ref={(e) => (inpRef.current[person.name] = e)}
                  />
                  <p>
                    {person.name &&
                      calculatePercentage(
                        selectedPeople.find((p) => p.name === person.name)
                          ?.value || 0,
                        parseFloat(amountPaid)
                      )}
                    %
                  </p>
                </label>
              );
            })}
        </div>

        {errorIndicator && <p>{errorIndicator}</p>}
        <button onClick={handleSubmit}>Submit</button>
      </div>
      <Chart1></Chart1>
    </div>
  );
}
