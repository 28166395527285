import React, { useState } from "react";
export default function TeamGenerator() {
  const [people, setPeople] = useState([]);
  const [person, setPerson] = useState("");
  const [teamsNumber, setTeamsNumber] = useState(2);
  const [generatedTeams, setGeneratedTeams] = useState(null);
  const [errorIndicator, setErrorIndicator] = useState(null);

  const handlePeopleChange = (e) => {
    setPerson(e.target.value);
  };

  const addPerson = () => {
    setErrorIndicator(null);
    if (person !== "") {
      setPeople((prev) => [...prev, person]);
      setPerson("");
    } else {
      setErrorIndicator("PODAJ OSOBĘ...");
    }
  };

  const handleTeamsNumber = (e) => {
    setErrorIndicator(null);
    setTeamsNumber(e.target.value);
  };

  const generateTeams = () => {
    // const temp = people
    setErrorIndicator(null);
    if(people.length > teamsNumber){
    const shuffledPeople = [...people].sort(
      () => Math.random() - Math.sin(Math.random())
    );
    const teams = Array.from({ length: teamsNumber }, () => []);
    shuffledPeople.forEach((p, index) => {
      const teamIndex = index % teamsNumber;
      teams[teamIndex].push(p);
    });
    setGeneratedTeams(teams);}else{
        setErrorIndicator("ZA MAŁA LICZBA OSÓB...");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addPerson();
    }
  };
  return (
    <div className="w-full flex justify-center items-start">
      <div className="w-5/6 bg-slate-300 p-4 rounded mt-8 min-w-80 flex flex-col gap-8 items-start md:w-4/5 md:flex-row">
        <div className="flex flex-col w-60 md:min-w-72">
          <p className="">Dodaj osoby do losowania:</p>
          <div className="my-4">
            <input
              type="text"
              name="name"
              id="name"
              onChange={handlePeopleChange}
              className="p-2 rounded w-40 md:max-w-52 md:w-auto"
              value={person}
              onKeyPress={handleKeyPress}
            />
            <button
              type="sumbit"
              className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center ml-2"
              onClick={addPerson}
            >
              DODAJ
            </button>
          </div>

          <select name="howMany" id="howMany" onChange={handleTeamsNumber} className="w-full">
            <option value="2">2 grupy</option>
            <option value="3">3 grupy</option>
            <option value="4">4 grupy</option>
            <option value="5">5 grupy</option>
            <option value="6">6 grupy</option>
            <option value="7">7 grupy</option>
            <option value="8">8 grupy</option>
          </select>
          <p className=" text-red-500 my-2 text-lg">{errorIndicator}</p>
        </div>
        <div className="flex flex-col w-full">
          <p>
            Dodane osoby (<span className=" font-bold">{people.length}</span>):
          </p>
          <div className="flex gap-4 flex-wrap">
            {people.map((p, index) => {
              return (
                <div className="" key={index}>
                  {p}
                </div>
              );
            })}
          </div>
          {people.length > 0 ? (
            <div className="">
              <button
                className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center my-8"
                onClick={generateTeams}
              >
                LOSUJ
              </button>
            </div>
          ) : null}
          {generatedTeams && (
            <div className="w-full">
              <p className="text-xl mb-2">Wylosowane drużyny:</p>
              <div className="grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4">
                {generatedTeams.map((team, index) => {
                  return (
                    <div className="flex flex-col" key={index}>
                      <p className=" font-bold">Drużyna {index + 1}:</p>
                      {team.map((p, index2) => {
                        return <div key={index2}>{p}</div>;
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
