import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import lock from '../assets/lock.svg'
import menuData from '../config/MenuData.json'
export default function PageSelector() {
    useEffect(() => {
        document.title = "Menu";
    }, []);
    return (
        <div className="w-full flex justify-center items-start">
            <div className="w-4/5 mt-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                {menuData.sort((a, b) => (a.locked === b.locked) ? 0 : a.locked ? 1 : -1).map((it, index) => (
                    <Link key={index} to={it.locked || it.projectPath}>
                        <div
                            className={
                                "flex flex-col justify-between p-4 h-48 rounded-lg shadow-2xl " +
                                (it.locked
                                    ? "bg-gray-600 text-white"
                                    : "hover:bg-slate-400 hover:scale-105 ease-in-out duration-150 hover:animate-pulse bg-zinc-300")
                            }
                        >
                            <div className="">
                                <p className="text-xl">{it.projectName}</p>
                                <p className="text-xs">
                                    {it.projectDescription}
                                </p>
                            </div>
                            {it.locked && (
                                <div className="flex flex-row-reverse">
                                  <img src={lock} alt="ProfilePic"/>
                                </div>
                            )}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}
