import React, { useEffect, useState } from "react";
import { ref, get, child } from "firebase/database";
import { real_db } from "../../config/firebaseConfig";
import { Link } from "react-router-dom"; 
export default function Pleiscyt() {

    const [posts, setPosts] = useState([]);
    useEffect(() => {
        document.title="Plebiscyt"
        const getPosts = async () => {
            await get(child(ref(real_db), "posts/")).then((snap) => {
                snap.forEach((element) => {
                    setPosts((prev) => {
                        return { ...prev, [element.key]: element.val() };
                    });
                });
            });
        };
        getPosts();
    }, []);

    return (
        <div className="w-full flex flex-col justify-start items-center bg-slate-700 text-slate-300 mt-8">
            <div className="mb-10 text-4xl text-center">
                Witamy w Plebiscycie na Najgłupsze Pytanie
            </div>
            <div className="flex w-4/5">
                <div className="w-1/5"></div>
                <p className="text-xl mb-10 w-3/5 text-center">
                    Oto wspaniałe głupotki szczelone przez naszych wspaniałych
                    debilków:
                </p>
                <div className="w-1/5 flex flex-row-reverse">
                    <Link to="/plebiscyt/nowy">
                    <div className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center">DODAJ NOWĄ MYŚL</div>
                    </Link>
                </div>
            </div>

            <table className="w-4/5 text-base text-left text-slate-300 ">
                <thead className="text-slate-300 bg-gray-600">
                    <tr>
                        <th className="px-6 py-3 w-1/5">Czyja głupotka:</th>
                        <th className="px-6 py-3 w-3/5">Treść głupotki:</th>
                        <th className="px-6 py-3 w-1/5">Data głupotki:</th>
                    </tr>
                </thead>
                <tbody>
                    {posts &&
                        Object.keys(posts).map((it) => (
                            <tr
                                key={it}
                                className="even:bg-gray-500 odd:bg-slate-600 border-b"
                            >
                                <td className="px-6 py-3 w-1/5">
                                    {posts[it].who}
                                </td>
                                <td className="px-6 py-3 w-1/5">
                                    {posts[it].description}
                                </td>
                                <td className="px-6 py-3 w-1/5">
                                    {posts[it].date}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
