import React, { useState } from "react";
import { createWorker } from "tesseract.js";
// import aa from "./Images/aa.jpg";
export default function Recognise() {
  const imageRecognison = async () => {
    const worker = await createWorker("pol");
    const response = await worker.recognize(image);
    setText(response.data);
    console.log(response);
    await worker.terminate();
  };

  const [text, setText] = useState(null);
  const [image, setImage] = useState(null);

  const handleImageupload = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };
  console.log(image);
//   console.log(text.lines);
  text && text.lines.map(it => console.log(it.text))
  return (
    <div className="w-full flex flex-col justify-center items-center text-slate-300 gap-8">
      <input type="file" name="files" id="files" onChange={handleImageupload} />
      <div className="">
        <button onClick={imageRecognison} className=" bg-red-600 p-2 rounded">
          Rozpoznaj
        </button>
      </div>
      <div className="w-full text-center">
        <p>Rozpoznanie:</p>
        {text && text.lines.map(it=> {
            return <p>{it.text}</p>
        })}
      </div>
    </div>
  );
}
