import React from "react";
import "./index.css";
import DodajNowy from "./pages/Plebiscyt/DodajNowy";
import Plebiscyt from "./pages/Plebiscyt/Plebiscyt";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DodajOsobe from "./pages/Interpol/admin/DodajOsobe";
import DodajGrupe from "./pages/Interpol/admin/DodajGrupe";
import PageSelector from "./pages/PageSelector";
import AdminPageSelector from "./pages/Interpol/admin/AdminPageSelector";
import Poszukiwani from "./pages/Interpol/UI/Poszukiwani";
import Group from "./pages/Interpol/UI/Group";
// import InDev from "./pages/InDev";
import UsersSelector from "./pages/Interpol/admin/UsersSelector";
import SplitSelector from "./pages/SplitApp/SplitSelector";
import NewExpese from "./pages/SplitApp/NewExpense";
import Expense from "./pages/SplitApp/Expense";
import SideNav from "./Components/Nav";
import TeamGenerator from "./pages/Generators/TeamGenerator";
import PickerWheel from "./pages/Generators/PickerWheel";
import Recognise from "./pages/Ocr/Recognise";
import Late from "./pages/Jesicka/Late";
import NowyLate from "./pages/Jesicka/NowyLate";
// import EdytujOsobe from "./pages/Interpol/admin/EdytujOsobe";
function App() {
  return (
    <div className="w-full flex flex-row">
      <BrowserRouter>
          <SideNav />
          <Routes>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<PageSelector />} />

            <Route path="/plebiscyt" element={<Plebiscyt />} />
            <Route path="/plebiscyt/nowy" element={<DodajNowy />} />

            <Route path="/interpol/admin" element={<AdminPageSelector />} />
            <Route path="/interpol/poszukiwani" element={<Poszukiwani />} />
            <Route path="/interpol/:groupId" element={<Group />} />
            <Route path="/interpol/nowaosoba" element={<DodajOsobe />} />
            <Route path="/interpol/nowagrupa" element={<DodajGrupe />} />
            <Route path="/interpol/edit" element={<UsersSelector />} />
            {/* <Route path="/interpol/edit/:userId" element={<EdytujOsobe />} /> */}

            <Route path="/jesicka" element={<Late />} />
            <Route path="/jesicka/nowy" element={<NowyLate />} />

            <Route path="/split" element={<SplitSelector />} />
            <Route path="/split/newexpense" element={<NewExpese />} />
            <Route path="/split/:expId" element={<Expense />} />

            <Route path="/teamgen" element={<TeamGenerator />} />
            <Route path="/pickerwheel" element={<PickerWheel />} />

            <Route path="/recognise" element={<Recognise />} />
            
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
