import { child, get, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { real_db } from "../../config/firebaseConfig";
export default function SplitSelector() {
  const [expenses, setExpenses] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await get(child(ref(real_db), "splitApp"));
      if (data.val() !== null) {
        setExpenses(data.val());
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(expenses);
  return (
    <div className="w-full flex flex-col justify-start items-center text-slate-300">
      <p className="text-4xl mt-8">Wybierz wydatki</p>
      <div className="w-4/5 grid grid-cols-4 mt-8 gap-8 text-black">
        {expenses &&
          Object.keys(expenses).map((it, index) => {
            return (
              <Link to={`/split/${it}`} key={index}>
                <div className="bg-slate-300 p-4 rounded-lg min-h-48 shadow-2xl flex flex-col justify-between hover:animate-pulse hover:scale-105 ease-in-out duration-150">
                  <div className="">
                    <p className="text-xl">{expenses[it].name}</p>
                    <p className="text-sm min-h-24">{expenses[it].desc}</p>
                  </div>
                  <div className="grid gap-4 grid-cols-4">
                    {expenses[it].people.map((it2, index) => {
                      return (
                        <div
                          className=" bg-emerald-500 p-2 rounded-full w-10 h-10 flex justify-center items-center font-semibold text-xl"
                          key={index}
                        >
                          {it2.name.charAt(0)}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Link>
            );
          })}
        
        <Link to="/split/newexpense">
          <div className=" border-slate-300 border-dashed border-4 p-4 rounded-lg min-h-48 shadow-2xl hover:animate-pulse hover:scale-105 ease-in-out duration-150 flex justify-center items-center">
            <p className=" text-slate-300 text-6xl">+</p>
          </div>
        </Link>
      </div>
    </div>
  );
}
