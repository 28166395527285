import { child, get, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { real_db } from "../../config/firebaseConfig";
import dwArr from "../../assets/dwArr.svg";
import upArr from "../../assets/upArr.svg";
import CalcStats from "./CalcStats";
export default function Late() {
  const [late, setLate] = useState(null);
  useEffect(() => {
    document.title = "JESICKA";
    const getLates = async () => {
      await get(child(ref(real_db), "/jesicka")).then((snap) => {
        snap.forEach((el) => {
          setLate((prev) => {
            return { ...prev, [el.key]: el.val() };
          });
        });
      });
    };
    getLates();
  }, []);
  
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full flex flex-col justify-start items-center bg-slate-700 mt-8 text-slate-300">
      <div className="mb-10 text-4xl text-center w-5/6 md:w-4/5">
        "Ja się już nie spóźniam" ~ Jessica 2k24
      </div>
      <div
        className="flex relative justify-between w-5/6 md:w-4/5"
        onClick={handleDropdown}
      >
        <span>Rozwiń statystyki</span>
        {isOpen ? (
          <img src={upArr} alt="dwArr" />
        ) : (
          <img src={dwArr} alt="upArr" />
        )}
      </div>
      <div className={`w-5/6 md:w-4/5 mb-4 ${isOpen ? " block " : " hidden"}`}>
        {late &&  <CalcStats data={late}/>}
      </div>
      <div className="flex flex-col w-5/6 md:w-4/5 md:flex-row gap-4 md:gap-0">
        <div className="md:w-1/3 "></div>
        <div className="md:w-1/3 flex flex-row-reverse md:order-3">
          <Link to="/jesicka/nowy">
            <div className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center">
              NOWE SPÓŹNIENIE
            </div>
          </Link>
        </div>
        <p className="text-xl md:w-1/3 text-center md:order-2">
          Ostatnie spóźnienia:
        </p>
      </div>

      <table className="w-5/6 md:w-4/5 text-base text-left text-slate-300 mt-4">
        <thead className="text-slate-300 bg-gray-600">
          <tr>
            <th className="px-4 py-2 md:px-6 md:py-3 w-1/6 md:w-1/5">
              Czas (minuty):
            </th>
            <th className="px-4 py-2 md:px-6 md:py-3 w-1/2 md:w-3/5">
              Na co się spóźniła:
            </th>
            <th className="px-4 py-2 md:px-6 md:py-3 w-1/3 md:w-1/5">
              Data spóźnienia:
            </th>
          </tr>
        </thead>
        <tbody>
          {late &&
            Object.keys(late).map((it) => {
              let temp = late[it].date;
              const date = new Date(temp);
              return (
                <tr
                  key={it}
                  className="even:bg-gray-500 odd:bg-slate-600 border-b"
                >
                  <td className="px-4 py-2 md:px-6 md:py-3 w-1/6 md:w-1/5">
                    {late[it].lateTime}
                  </td>
                  <td className="px-4 py-2 md:px-6 md:py-3 w-1/2 md:w-3/5">
                    {late[it].lateFor}
                  </td>
                  <td className="px-4 py-2 md:px-6 md:py-3 w-1/3 md:w-1/5">
                    {date.toLocaleString()}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
