import React from "react";
import menuData from "../config/MenuData.json";
import { Link } from "react-router-dom";
import homeIcon from "../assets/home.svg";

export default function SideNav() {
  return (
    <div className="md:w-20 bg-slate-800 md:h-screen fixed md:sticky md:top-0 flex flex-row md:flex md:justify-center text-slate-300 z-50 bottom-0 h-20 w-full">
      <div className="flex flex-row md:flex-col md:w-12 gap-4 md:mt-8  h-full md:h-auto items-center overflow-scroll md:overflow-hidden ml-4 md:ml-0">
        <Link to="/home">
          <div className="roudned box-border w-12 border-2 flex items-center justify-center border-slate-600 h-12 rounded hover:border-slate-300">
            <img src={homeIcon} alt="HomeIcon" className=" fill-slate-300" />
          </div>
        </Link>

        {menuData.sort((a, b) => (a.locked === b.locked) ? 0 : a.locked ? 1 : -1).map((page, index) => {
          return page.locked ? (
            <div key={index} className="roudned box-border opacity-25 w-12 border-2 flex items-center justify-center border-slate-600 h-12 rounded hover:border-slate-300">
              {page.projectShortName}
            </div>
          ) : (
            <div className="" key={index}>
              <Link to={`${page.projectPath}`}>
                <div className="roudned box-border border-2 w-12 flex items-center justify-center border-slate-600 h-12 rounded hover:border-slate-300">
                  {page.projectShortName}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
