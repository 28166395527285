import React, { useState } from "react";
import rtp from "../../assets/rightTrianglePointer.svg";
import { useNavigate } from "react-router-dom";
import { push, ref, set } from "firebase/database";
import { real_db } from "../../config/firebaseConfig";
export default function NewExpese() {
  const nav = useNavigate();
  const [people, setPeople] = useState([]);
  const handleAddNewPerson = () => {
    let personTemplate = {
      name: person,
      totalAmountBorrowedUp: 0,
      totalAmountBorrowedFrom: 0,
    };
    if (person.trim("") !== "") {
      setPeople((prev) => [...prev, personTemplate]);
      setPerson("");
    }
  };
  const [person, setPerson] = useState("");
  const handlePersonChange = (e) => {
    setPerson(e.target.value);
  };
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const handleSetName = (e) => {
    setName(e.target.value);
    setErrorCode(null);
  };
  const handleSetDesc = (e) => {
    setDesc(e.target.value);
    setErrorCode(null);
  };

  const [errorCode, setErrorCode] = useState(null);

  const uploadData = async () => {
    if (name.trim("") !== "" && desc.trim("") !== "" && people.length !== 0) {
      await set(push(ref(real_db, "splitApp")), {
        name: name,
        desc: desc,
        people: people,
        totalExpensesAmount: 0,
        createdAt: Date.now(),
      });
      nav("/split");
    } else {
      setErrorCode("Pola nie mogą być puste");
    }
  };
  console.log(person)
  return (
    <div className="flex flex-col justify-center items-center">
      <p className="text-4xl mt-8 text-slate-300">Dodaj nowy wydatek:</p>
      <div className="flex flex-col w-1/2 bg-slate-300 rounded-xl p-4 mt-8 gap-2">
        <div className="flex flex-row gap-2">
          <div className="w-1/2">
            <p>Nazwa:</p>
            <input
              type="text"
              name="name"
              id="name"
              className="p-1 rounded bg-transparent border-slate-600 border-2 border-dashed"
              onChange={handleSetName}
            />
            <p>Opis:</p>
            <textarea
              name="desc"
              id="desc"
              cols="30"
              rows="5"
              className="p-1 rounded bg-transparent border-slate-600 border-2 border-dashed"
              onChange={handleSetDesc}
            ></textarea>
            {errorCode && (
              <span className=" text-red-700">
                <br />
                {errorCode}
              </span>
            )}
            <p>Dodaj nową osobę:</p>
            <input
              type="text"
              name="person"
              id="person"
              value={person}
              className="p-1 rounded bg-transparent border-slate-600 border-2 border-dashed"
              onChange={handlePersonChange}
              maxLength='10'
            />
            <button
              className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center ml-2"
              onClick={handleAddNewPerson}
            >
              Dodaj +
            </button>
          </div>
          <div className="w-1/2">
            <p>Dodane osoby:</p>
            {people &&
              Object.keys(people).map((it, index) => {
                return (
                  <div className="flex mt-2" key={index}>
                    <img src={rtp} alt="rtp" />
                    <span className="bg-emerald-500 p-2 rounded-full w-10 h-10 flex justify-center items-center font-bold">
                      {people[it].name.charAt(0).toUpperCase()}
                    </span>
                    <span className="flex justify-center items-center ml-2">
                      {people[it].name.charAt(0).toUpperCase() + people[it].name.slice(1)}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <button
            className="mt-4 bg-red-500 p-2 rounded-md hover:bg-red-700 text-center ml-2"
            onClick={uploadData}
          >
            DODAJ WYDATEK
          </button>
        </div>
      </div>
    </div>
  );
}
