import React, { useEffect } from "react";
import { Link } from "react-router-dom";
export default function AdminPageSelector() {
  useEffect(() => {
    document.title = "Admin";
  }, []);
  const info = [
    {
      addType: "Dodaj nową osobę",
      path: "/interpol/nowaosoba",
    },
    {
      addType: "Dodaj nową grupę",
      path: "/interpol/nowagrupa",
    },
    // {
    //     addType: 'Edytuj Osobę',
    //     path: '/interpol/edit'
    // }
  ];

  return (
    <div className="w-full flex justify-center items-start">
      <div className="w-4/5 mt-16 grid grid-cols-3 gap-8">
        {info.map((it, index) => (
          <Link key={index} to={it.path}>
            <div className=" bg-zinc-300 p-4 h-48 rounded-lg shadow-2xl hover:scale-105 hover:animate-pulse hover:bg-slate-400 ease-in-out duration-150">
              <p className="text-xl">{it.addType}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
