import React, { useEffect, useState } from "react";
import { set, ref, get, child } from "firebase/database";
import { real_db, stor_db } from "../../config/firebaseConfig";
import { ref as ref_storage } from "firebase/storage";
import { getDownloadURL } from "firebase/storage";
import { Link, useNavigate } from "react-router-dom";
export default function DodajNowy() {
    const nav = useNavigate();
    const [users, setUsers] = useState(null);
    const [load, setLoad] = useState(true);
    const [selectedPerson, setSelectedPerson] = useState(null);
    const [text, setText] = useState(null);

    const handleChange = (e) => {
        setSelectedPerson(e.target.value);
    };

    const handleText = (e) => {
        setText(e.target.value);
    };

    useEffect(() => {
        const fetchData = async () => {
            const snap = await get(child(ref(real_db), "users/"));
            let fu = snap.val();
            const promises = Object.keys(fu).map(async (it) => {
                const snap2 = await getDownloadURL(
                    ref_storage(stor_db, fu[it].imageRef)
                );
                fu[it].image = snap2;
            });
            await Promise.all(promises);
            setUsers(fu);
            setLoad(false);
        };

        fetchData();
    }, []);

    const addNewRecord = async () => {
        const data = new Date();
        await set(ref(real_db, "posts/" + data.getTime()), {
            who: selectedPerson,
            description: text,
            date: data.toLocaleString(),
        });
        nav('/plebiscyt');
    };

    return (
        <div className="flex flex-col justify-start items-center text-slate-300 mt-8 w-full" >
            <div className="mb-10 text-4xl">
                Witamy w Plebiscycie na Najgłupsze Pytanie
            </div>
            <p className="text-xl mb-10 w-1/2 text-center">
                Dodaj nową głupotę naszego wspaniałego członka do bazy poprzez
                wybranie jednej z poniższych osób.
            </p>
            <div className="flex flex-row w-1/2">
                <div className="flex w-1/5">
                    <div className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center">
                        <Link to="/plebiscyt">Cofnij się</Link>
                    </div>
                </div>

                <p className="w-3/5 text-center">Kto jebnął głupotką:</p>
                <div className="w-1/5"></div>
            </div>

            <fieldset className="flex flex-wrap justify-center w-1/2 mb-4">
                {load ? (
                    <p>Loading</p>
                ) : (
                    users &&
                    Object.keys(users).map((it) => (
                        <div className="" key={it}>
                            <label>
                                <div className="flex flex-col max-w-28 justify-center items-center m-2  cursor-pointer border-2 has-[:checked]:border-red-700 rounded-xl p-2">
                                    <input
                                        type="radio"
                                        name="id1"
                                        onChange={handleChange}
                                        value={users[it].user}
                                        className="hidden overflow-hidden"
                                    />
                                    <p>{users[it].user}</p>
                                    <img
                                        className="rounded-xl"
                                        src={users[it].image}
                                        alt={users[it].user}
                                    />
                                </div>
                            </label>
                        </div>
                    ))
                )}
            </fieldset>
            <p>A teraz napisz tą wspaniałą myśl:</p>
            <textarea
                rows="5"
                className="w-1/2 border-2 border-black p-2 mb-10 bg-slate-600"
                onChange={handleText}
            ></textarea>
            <button
                className=" bg-red-500 p-2 rounded-md hover:bg-red-700"
                onClick={addNewRecord}
            >
                ZAPISZ MYŚL
            </button>
        </div>
    );
}
