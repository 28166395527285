import { real_db, stor_db } from "../../../config/firebaseConfig";
import { child, get, ref, set } from "firebase/database";
import {
    uploadBytes,
    ref as storage_ref,
    getDownloadURL,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function DodajOsobe() {
  const nav = useNavigate()
    const [users, setUsers] = useState(null);

    const [groups, setGropus] = useState([]);

    const [name, setName] = useState(null);
    const [age, setAge] = useState(null);
    const [sex, setSex] = useState(null);
    const [nickname, setNickname] = useState(null);
    const [image, setImage] = useState(null);
    const [wantedFor, setWantedFor] = useState([""]);

    const handleName = (e) => {
        setName(e.target.value);
    };
    const handleAge = (e) => {
        setAge(e.target.value);
    };
    const handleSex = (e) => {
        setSex(e.target.value);
    };
    const handleNickname = (e) => {
        setNickname(e.target.value);
    };
    const handleGroups = (e) => {
        const temp = JSON.parse(e);
        console.log(temp)
        setGropus(prev =>{
            const index = prev.findIndex(e=>e.id === temp.id);
            if(index !== -1){
                const newGroups=[...prev];
                newGroups.splice(index, 1);
                return newGroups;
            }else{
                return[...prev,temp];
            }
        })
    };
    // NIE DOTYKAĆ I NIE ZMIENIAĆ NICZEGO, GROZI WYJEBANIEM BAZY W PIZDU NI CHUJA
    const uploadDataToDataBase = async () => {
        let timestamp = Date.now();
        await set(ref(real_db, "usersInterpol/" + timestamp), {
            // NI CHUJA NIE ZMIENIAJ WARTOŚCI TEJ ^
            name: name,
            image: timestamp,
            sex: sex,
            age: age,
            nickname: nickname,
            wantedFor: wantedFor,
            groups: groups,
        }).then(
            await uploadBytes(
                storage_ref(stor_db, "usersInterpol/" + timestamp),
                // NI CHUJA NIE ZMIENIAJ WARTOŚCI TEJ ^
                image
            )
        );
        nav('/interpol/poszukiwani');
    };
    // POBIERANIE WARTOŚCI BAZOWYCH
    useEffect(() => {
        const fetchData = async () => {
            const snap = await get(child(ref(real_db), "groupsInterpol/"));
            if (snap.val() !== null) {
                let fu = snap.val();

                const promises = Object.keys(fu).map(async (it) => {
                    const snap2 = await getDownloadURL(
                        storage_ref(stor_db, "groupsInterpol/" + fu[it].image)
                    );
                    fu[it].image = snap2;
                    fu[it].path = it;
                });
                await Promise.all(promises);
                setUsers(Object.values(fu));
            }
        };
        fetchData();
    }, []);

    const addNewRow = () => {
        const newData = [...wantedFor, ""];
        setWantedFor(newData);
    };

    const handleWantedFor = (e, index) => {
        const newData = [...wantedFor];
        newData[index] = e.target.value;
        setWantedFor(newData);
    };
    return (
        <div className="w-full flex justify-center">
            <div className="text-slate-300 flex flex-col justify-center items-center mt-20 bg-white bg-opacity-15 rounded-xl p-6 border-2 border-white backdrop-blur-xl w-2/3">
                <p className="text-3xl mb-4">DODAJ NOWĄ OSOBĘ</p>
                {image && (
                    <img
                        src={URL.createObjectURL(image)}
                        className=" max-w-44"
                        alt="ProfileImage"
                    />
                )}
                <div className="flex flex-col gap-4">
                    {/* INP NAME */}
                    <div className="flex flex-col">
                        <label htmlFor="name">Imię</label>
                        <input
                            type="text"
                            name="name"
                            className="text-black p-2 rounded-lg"
                            onChange={handleName}
                        />
                    </div>
                    {/* INP AGE */}
                    <div className="flex flex-col">
                        <label htmlFor="age">Wiek</label>
                        <input
                            type="number"
                            name="age"
                            className="text-black p-2 rounded-lg"
                            onChange={handleAge}
                        />
                    </div>
                    {/* INP SEX */}
                    <div className="flex flex-col">
                        <label htmlFor="sex">Płeć</label>
                        <input
                            type="text"
                            name="sex"
                            className="text-black p-2 rounded-lg"
                            onChange={handleSex}
                        />
                    </div>
                    {/* INP NICKNAME */}
                    <div className="flex flex-col">
                        <label htmlFor="nickname">Pseudonim</label>
                        <input
                            type="text"
                            name="nickname"
                            className="text-black p-2 rounded-lg"
                            onChange={handleNickname}
                        />
                    </div>
                    {users && <p>Podaj grupę, jeżeli w niej uczestniczy</p>}
                    {/* MIEJSCE NA CHECKBOXY GRUP W FORMIE KAFELEK */}
                    <div className="flex">
                        {users &&
                            Object.keys(users).map((it) => {
                                let dat = JSON.stringify({
                                    id: users[it].path,
                                    name: users[it].name,
                                });

                                return (
                                    <label key={it}>
                                        <div
                                            className="m-2 p-2 flex flex-col justify-center border-2 max-w-28 border-slate-400 items-center has-[:checked]:border-red-600 box-border hover:border-red-600"
                                            key={it}
                                        >
                                            <span>{users[it].name}</span>
                                            <input
                                                type="checkbox"
                                                value={dat}
                                                onChange={(e) =>
                                                    handleGroups(e.target.value)
                                                }
                                                className="hidden overflow-hidden"
                                            />
                                            <img
                                                src={users[it].image}
                                                alt={users[it].name}
                                            />
                                        </div>
                                    </label>
                                );
                            })}
                    </div>
                    {/* MIEJSCE NA DYNAMICZNĄ TABLĘ - WANTED FOR */}
                    <div className="w-full flex justify-between">
                        <div className="w-2/3">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="text-left">
                                            Poszukiwany/na za
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {wantedFor &&
                                        wantedFor.map((val, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={val}
                                                        onChange={(e) =>
                                                            handleWantedFor(
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        className="p-2 rounded-lg text-black w-full"
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="w-1/3 flex items-end flex-row-reverse">
                            <div>
                                <button
                                    onClick={addNewRow}
                                    className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center"
                                >
                                    Dodaj +{" "}
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* MIEJSCE NA UPLOAD ZDJĘCIA DANEJ OSOBY */}
                    <label htmlFor="image">Wybierz zdjęcie:</label>
                    <input
                        type="file"
                        name="image"
                        onChange={(e) => setImage(e.target.files[0])}
                    />
                </div>
                <button
                    onClick={uploadDataToDataBase}
                    className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center mt-8"
                >
                    UPLOAD
                </button>
            </div>
        </div>
    );
}
