import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { real_db, stor_db } from "../../../config/firebaseConfig";
import { child, get, ref } from "firebase/database";
import { ref as storage_ref, getDownloadURL } from "firebase/storage";
import bcArr from "../../../assets/svgLtArr.svg";
export default function Group() {
    const { groupId } = useParams();
    const [group, setGroup] = useState(null);

    useEffect(() => {
        document.title = "Interpol";
        const fetchData = async () => {
            const snap = await get(
                child(ref(real_db), "groupsInterpol/" + groupId)
            );
            let fu = snap.val();
            fu.image = await getDownloadURL(
                storage_ref(stor_db, "groupsInterpol/" + groupId)
            );
            setGroup(fu);
        };

        fetchData();
    },[groupId]);
    return (
        <div className="w-full flex justify-center items-start text-slate-300">
            <div className="w-2/3">
                <p className="text-4xl text-center my-8">
                    BAZA POSZUKIWANYCH INTERPOL
                    <span className="text-xs">sl</span>
                </p>
                <Link to="/interpol/poszukiwani">
                    <img
                        src={bcArr}
                        alt="backArrow"
                        className=" inline-block"
                    />
                    Powrót do poprzedniej strony
                </Link>

                {group && (
                    <div className="flex flex-col md:flex-row shadow-md md:justify-between bg-slate-300 text-black p-6 rounded-xl my-8">
                        <div className=" flex justify-center md:block md:w-1/4">
                            <img
                                src={group.image}
                                alt={group.codename}
                                className="max-h-80 md:max-h-none"
                            />
                        </div>
                        <div className="md:w-3/4 md:ml-10">
                            <h1 className="text-xl mb-4">
                                <b>Misja:</b>{" "}
                                <span className="md:hidden">
                                    <br />
                                </span>
                                {group.name}
                            </h1>
                            <p className="mb-4">
                                <b>Kryptonim: </b>
                                {group.codename}
                            </p>
                            <p className="mb-4">
                                <b>Opis: </b> {group.description}
                            </p>
                            <p>
                                <b>Kara: </b>
                                {group.penalty}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
