import { real_db, stor_db } from "../../../config/firebaseConfig";
import { child, get, push, ref, set } from "firebase/database";
import {
    uploadBytes,
    ref as storage_ref,
    getDownloadURL,
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function DodajGrupe() {
    const nav = useNavigate();

    const [groups, setGropus] = useState(null);
    const [users, setUsers] = useState([]);

    const [image, setImage] = useState(null);
    const [codename, setCodename] = useState(null);
    const [name, setName] = useState(null);
    const [description, setDescription] = useState(null);
    const [penalty, setPenalty] = useState(null);

    const handleCodename = (e) => {
        setCodename(e.target.value);
    };
    const handleName = (e) => {
        setName(e.target.value);
    };
    const handleDescription = (e) => {
        setDescription(e.target.value);
    };
    const handlePenalty = (e) => {
        setPenalty(e.target.value);
    };
    const handleUsers = (e) => {
        if (users.includes(e)) {
            setUsers(users.filter((it) => it !== e));
        } else {
            setUsers([...users, e]);
        }
    };
    //NI CHUJA NIE ZMIENIAĆ I USUWAĆ
    const uploadDataToDataBase = async () => {
        let timestamp = Date.now();
        await set(ref(real_db, "groupsInterpol/" + timestamp), {
            name: name,
            image: timestamp,
            codename: codename,
            description: description,
            penalty: penalty,
        }).then(
            await uploadBytes(
                storage_ref(stor_db, "groupsInterpol/" + timestamp),
                image
            )
        ).then(
          users && users.forEach( async it => {
            const dataToPush = {
              id: timestamp,
              name: name,
            }
            await set(push(ref(real_db, 'usersInterpol/' + it + '/groups')),dataToPush)
          })
        )
        nav("/interpol/poszukiwani");
    };
    
    useEffect(() => {
        const fetchData = async () => {
            const snap = await get(child(ref(real_db), "usersInterpol/"));
            if (snap.val() !== null) {
                let fu = snap.val();
                const promises = Object.keys(fu).map(async (it) => {
                    const snap2 = await getDownloadURL(
                        storage_ref(stor_db, "usersInterpol/" + fu[it].image)
                    );
                    fu[it].image = snap2;
                });
                await Promise.all(promises);
                setGropus(fu);
            }
        };
        fetchData();
    },[]);
    console.log(groups);
    return (
        <div className="w-full flex justify-center">
            <div className="text-slate-300 flex flex-col justify-center items-center mt-20 bg-white bg-opacity-15 rounded-xl p-6 border-2 border-white backdrop-blur-xl w-2/3">
                <p className="text-3xl mb-4">DODAJ NOWĄ GRUPĘ</p>
                <div className="flex flex-col gap-4">
                    {/* INP KRYPTONIM */}
                    <div className="flex flex-col">
                        <label htmlFor="codename">Kryptonim</label>
                        <input
                            type="text"
                            name="codename"
                            className="text-black p-2 rounded-lg"
                            onChange={handleCodename}
                        />
                    </div>

                    {/* INP NAZWA */}
                    <div className="flex flex-col">
                        <label htmlFor="name">Nazwa</label>
                        <input
                            type="text"
                            name="name"
                            className="text-black p-2 rounded-lg"
                            onChange={handleName}
                        />
                    </div>
                    {/* TEXTAREA DESCRIPTION */}
                    <div className="flex flex-col">
                        <label htmlFor="description">Opis zbrodni</label>
                        <textarea
                            name="description"
                            rows="5"
                            className="rounded-lg text-black p-2"
                            onChange={handleDescription}
                        />
                    </div>

                    {/* TEXTAREA PENALTY */}
                    <div className="flex flex-col">
                        <label htmlFor="penalty">Kara</label>
                        <textarea
                            name="penalty"
                            rows="5"
                            className="rounded-lg text-black p-2"
                            onChange={handlePenalty}
                        />
                    </div>
                    {/* MIEJSCE NA CHECKBOXY W FORMIE KAFELEK */}
                    <div className="flex">
                        {groups &&
                            Object.keys(groups).map((it) => {
                              
                              
                              return(
                                <label key={it}>
                                    <div
                                        className="m-2 p-2 flex flex-col justify-center border-2 max-w-28 border-slate-400 items-center has-[:checked]:border-red-600 box-border hover:border-red-600"
                                        key={it}
                                    >
                                        <span>{groups[it].name}</span>
                                        <input
                                            type="checkbox"
                                            value={it}
                                            onChange={(e) =>
                                                handleUsers(e.target.value)
                                            }
                                            className="hidden overflow-hidden"
                                        />
                                        <img
                                            src={groups[it].image}
                                            alt={groups[it].name}
                                        />
                                    </div>
                                </label>
                            )})}
                    </div>

                    {/* MIEJSCE NA UPLOAD ZDJĘCIA DANEJ GRUPY */}
                    <label htmlFor="image">Wybierz zdjęcie:</label>
                    <input
                        type="file"
                        name="image"
                        onChange={(e) => setImage(e.target.files[0])}
                    />
                </div>

                <button
                    onClick={uploadDataToDataBase}
                    // onClick={tempfunk}
                    className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center mt-8"
                >
                    UPLOAD
                </button>
            </div>
        </div>
    );
}
