import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import dwArr from "../../assets/dwArr.svg";
import upArr from "../../assets/upArr.svg";
import { ref, set } from "firebase/database";
import { real_db } from "../../config/firebaseConfig";
export default function NowyLate() {
  const nav = useNavigate();
  const [lateFor, setLateFor] = useState(null);

  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);

  const handleUpMinutes = () => {
    setMinutes((prevMinute) => (prevMinute + 1) % 60);
  };
  const handleDownMinutes = () => {
    setMinutes((prevMinute) => (prevMinute - 1 + 60) % 60);
  };
  const handleUpHours = () => {
    setHours((prevHour) => (prevHour + 1) % 24);
  };
  const handleDownHours = () => {
    setHours((prevHour) => (prevHour - 1 + 24) % 24);
  };

  const handleLateFor = (e) => {
    setLateFor(e.target.value);
  };

  const upoladData = async () => {
    const data = Date.now();
    await set(ref(real_db, "jesicka/" + data), {
      lateFor: lateFor,
      lateTime: hours * 60 + minutes,
      date: data,
    });
    nav("/jesicka");
  };



  return (
    <div className="flex flex-col justify-start items-center text-slate-300 mt-8 w-full">
      <div className="w-5/6">
        <div className="mb-10 text-4xl text-center">
          "Ja się już nie spóźniam" ~ Jessica 2k24
        </div>
        <div className="flex flex-col md:flex-row w-full gap-2 md:gap-0">
          <div className="flex md:w-1/5">
            <div className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center">
              <Link to="/jesicka">Cofnij się</Link>
            </div>
          </div>
          <p className="md:w-3/5 md:text-center">Dodaj nowe spóźnienie Jesicki: </p>
          <div className="md:w-1/5 hidden md:block"></div>
        </div>

        <div className="mt-2 md:mt-8 gap-4 flex flex-col ">
          <div className="flex flex-col w-full">
            <label htmlFor="lateFor">Śpóźnienie na:</label>
            <textarea
              type="text"
              name="lateFor"
              id="lateFor"
              onChange={handleLateFor}
              className="p-1 rounded w-full text-black"
            />
          </div>

          <div className="flex flex-col md:flex-row md:justify-between gap-2 w-full">
            <div className="flex gap-1">
              <p className="flex justify-start items-center">
                Ile się spóźniła (HH:MM):
              </p>
              <div className="flex flex-col">
                <button
                  className=" bg-red-500 p-1 rounded hover:bg-red-600 flex flex-col justify-center items-center"
                  onClick={handleUpHours}
                >
                  <img src={upArr} alt="upArr" />
                </button>
                <span className="text-center p-2">{hours}</span>
                <button
                  className="bg-red-500 p-1 rounded hover:bg-red-600 flex flex-col justify-center items-center"
                  onClick={handleDownHours}
                >
                  <img src={dwArr} alt="dwArr" />
                </button>
              </div>
              <div className="flex justify-center items-center">:</div>
              <div className="flex flex-col">
                <button
                  className=" bg-red-500 p-1 rounded hover:bg-red-600 flex flex-col justify-center items-center"
                  onClick={handleUpMinutes}
                >
                  <img src={upArr} alt="upArr" />
                </button>
                <span className="text-center p-2">{minutes}</span>
                <button
                  className="bg-red-500 p-1 rounded hover:bg-red-600 flex flex-col justify-center items-center"
                  onClick={handleDownMinutes}
                >
                  <img src={dwArr} alt="dwArr" />
                </button>
              </div>
            </div>
            <div className="flex justify-end md:justify-center items-center">
              <div className="">
                <button
                  className="bg-red-500 p-2 rounded-md hover:bg-red-700 text-center"
                  onClick={upoladData}
                >
                  DODAJ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
