import { child, get, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { real_db, stor_db } from "../../../config/firebaseConfig";
import { getDownloadURL, ref as storage_ref } from "firebase/storage";
import { Link } from "react-router-dom";
import rArr from "../../../assets/rtArr.png";

export default function Poszukiwani() {
    const [users, setUsers] = useState(null);
    useEffect(() => {
        document.title = "Interpol";
        const fetchData = async () => {
            const snap = await get(child(ref(real_db), "usersInterpol/"));
            if (snap.val() !== null) {
                let fu = snap.val();
                const promises = Object.keys(fu).map(async (it) => {
                    const snap2 = await getDownloadURL(
                        storage_ref(stor_db, "usersInterpol/" + fu[it].image)
                    );
                    fu[it].image = snap2;
                });
                await Promise.all(promises);
                setUsers(Object.values(fu));
            }
        };

        fetchData();
    }, []);
    return (
        <div className="w-full flex flex-col justify-start items-center text-slate-300">
            <p className="text-4xl text-center mt-8">
                BAZA POSZUKIWANYCH INTERPOL<span className="text-xs">sl</span>
            </p>
            <div className="w-4/5 mt-8">
                <div className="mt-6 grid grid-cols-1 gap-x-20 gap-y-20 md:grid-cols-2">
                    {users &&
                        users.map((it, index) => {
                            return (
                                <div
                                    className="flex flex-col shadow-xl border-gray-500 p-4 bg-slate-300 text-black rounded-lg"
                                    key={index}
                                >
                                    <div className="w-full flex flex-col md:flex-row">
                                        <div className="w-full md:w-1/3  md:max-h-56 md:order-2 ">
                                            <img
                                                className="max-h-56 m-auto md:m-0 md:float-right"
                                                src={it.image}
                                                alt={`Wanted: ${it.name}`}
                                            />
                                        </div>
                                        <div className="w-full md:w-2/3">
                                            <h1>Imię: {it.name}</h1>
                                            <p>Wiek: {it.age}</p>
                                            <p>Płeć: {it.sex}</p>
                                            <p>Pseudonim: {it.nickname}</p>
                                            {it.groups && (
                                                <div className="mt-6">
                                                    <h3>
                                                        Członek nielegalnych
                                                        organizacji:
                                                    </h3>
                                                    <ul>
                                                        {Object.keys(
                                                            it.groups
                                                        ).map((it2) => {
                                                            return (
                                                                <Link
                                                                    key={it2}
                                                                    to={`/interpol/${it.groups[it2].id}`}
                                                                >
                                                                    <li>
                                                                        <img
                                                                            src={
                                                                                rArr
                                                                            }
                                                                            alt="Arrow"
                                                                            className=" inline"
                                                                        />
                                                                        {
                                                                            it
                                                                                .groups[
                                                                                it2
                                                                            ]
                                                                                .name
                                                                        }
                                                                    </li>
                                                                </Link>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <h3>Wanted For:</h3>
                                        <ul className="list-disc">
                                            {it.wantedFor.map(
                                                (crime, index) => (
                                                    <li
                                                        key={index}
                                                        className="ml-6"
                                                    >
                                                        {crime}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}
